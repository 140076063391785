import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'eightbit';

  icon: string;

  constructor(private _snackbar: MatSnackBar) {
    this._snackbar.open('Coming soon...', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    })

    if (this.checkDarkMode()) {
      this.icon = 'dark_mode';
    } else {
      this.icon = 'light_mode';
    }
  }

  toggleDarkMode() {
    console.log('toggled dark mode');
    localStorage.setItem('isDarkMode', JSON.stringify(!this.checkDarkMode()));
    location.reload();
    return false;
  }

  checkDarkMode(): boolean {
    let isDark = localStorage.getItem('isDarkMode');
    var bIsDark;
    if (isDark) {
      bIsDark = true;
    } else {
      bIsDark = false;
    }
    return bIsDark;
  }
}
