<!DOCTYPE html>
<html>
  <header>
    <mat-toolbar color='primary'>
      <button mat-icon-button type="button" mat-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Eight Bit Airways</span>
      <span class="spacer"></span>
      <button mat-icon-button class="toolbar-icon account-icon" aria-label="icon">
        <mat-icon>person</mat-icon>
      </button>
      <button mat-icon-button (click)="toggleDarkMode()">
        <mat-icon>{{ icon }}</mat-icon>
      </button>
    </mat-toolbar>

  </header>
  <body>    
    <mat-drawer-container class="main-container" autosize>
      <mat-drawer #drawer class="main-drawer" mode="side">
        <h1>Customer Areas</h1>
        <mat-list>
          <mat-list-item>
            <div class="nav-list-item">
              <mat-icon class="nav-list-icon">home</mat-icon>
              <button class="nav-list-button" mat-button routerLink="" (click)="drawer.toggle()">Home</button>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="nav-list-item">
              <mat-icon>luggage</mat-icon>
              <button mat-button routerLink="/paxops" (click)="drawer.toggle()">Passenger Operations</button>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="nav-list-item">
              <mat-icon>commute</mat-icon>
              <button mat-button routerLink="/cargoops" (click)="drawer.toggle()">Cargo Operations</button>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="list-divider"></div>
        <h1>Employee Areas</h1>
      </mat-drawer>
      <mat-drawer-content>
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  </body>
</html>
