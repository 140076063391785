import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CargoComponent } from './cargo/cargo.component';

import { LandingComponent } from './landing/landing.component';
import { PaxComponent } from './pax/pax.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'paxops', component: PaxComponent },
  { path: 'cargoops', component: CargoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
