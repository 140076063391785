import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OverlayContainer } from '@angular/cdk/overlay'; 

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LandingComponent } from './landing/landing.component';
import { PaxComponent } from './pax/pax.component';
import { CargoComponent } from './cargo/cargo.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    PaxComponent,
    CargoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _overlayContainer: OverlayContainer) {
    let isDark = localStorage.getItem('isDarkMode')
    if (isDark) {
      let b = JSON.parse(isDark);
      if (b) {
        _overlayContainer.getContainerElement().classList.add('eightbit-dark-theme');
      } else {
        _overlayContainer.getContainerElement().classList.add('eightbit-light-theme');
      }
    }
  }
 }
